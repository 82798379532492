exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.39.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".UspBarContentBlock__Bar_bar{display:flex;height:100%;width:100%}", ""]);

// exports
exports.locals = {
	"bar": "UspBarContentBlock__Bar_bar"
};