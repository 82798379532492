import React, { useState, useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styles from './VerticalScroll.module.scss';
import { renderHTML } from 'sana/utils';
import Accessibility from '../Accessibility';

export function adjustVerticalAlignment(elements, coverRef) {
  if (coverRef.current) {
    elements[0].top = -1 * coverRef.current.clientHeight;
    elements[1].top = 0;
    elements[2].top = coverRef.current.clientHeight;
  }
  return elements;
}

export function updateIndexConditionaly(state, rotationDelay, textLines, indexForSecondText, indexForThirdText) {
  if (state.elements[0].top === state.elements[1].top || rotationDelay === 0) {
    const elements = updateTextWithVisualDesignerChanges(state, textLines, indexForSecondText, indexForThirdText);
    return [state.index, elements, elements[1].backgroundColor];
  } else
    return [0, state.elements, state.elements[1].backgroundColor];
}

export function updateTextWithVisualDesignerChanges(state, textLines, indexForSecondText, indexForThirdText) {
  if (textLines.length === 1) {
    return [
      { ...state.elements[0], ...textLines[0] },
      { ...state.elements[1], ...textLines[0] },
      { ...state.elements[2], ...textLines[0] },
    ];
  }
  else if (textLines.length === 2) {
    return [
      { ...state.elements[indexForSecondText] },
      { ...state.elements[1], ...textLines[0] },
      { ...state.elements[indexForThirdText], ...textLines[1] },
    ];
  }
  else {
    return [
      { ...state.elements[indexForSecondText], ...textLines[2] },
      { ...state.elements[1], ...textLines[0] },
      { ...state.elements[indexForThirdText], ...textLines[1] },
    ];
  }
}

export function getVerticalAlignmentBaseOnRenderedElements(
  rotationDelay,
  coverRef,
  textLines,
  indexForSecondText,
  indexForThirdText) {
  return state => {
    const [index, elements, backgroundColor] =
      updateIndexConditionaly(state, rotationDelay, textLines, indexForSecondText, indexForThirdText);
    return {
      ...state,
      index,
      backgroundColor,
      elements: adjustVerticalAlignment(elements, coverRef),
    };
  };
}

export function setTop(index, elementToRepresentNextText, coverRef) {
  if (index === 0)
    return -1 * (elementToRepresentNextText.current.clientHeight + coverRef.current.clientHeight);
  else if (index === 1)
    return 0;
  else if (index === 2)
    return coverRef.current.clientHeight + elementToRepresentNextText.current.clientHeight;
}

export function modifyTextLine(element, index, transitionRef, textLines, elementToRepresentNextText, coverRef,
  getTransitionsIndex) {
  element.index = getTransitionsIndex(element.index);
  element.visibility = 'visible';
  element.transition = transitionRef[element.index];
  element.top = setTop(element.index, elementToRepresentNextText, coverRef);
  if (element.transition === 0) {
    const theNextAfterNext = getTextIndex(index, textLines);
    element.fontColor = textLines[theNextAfterNext].fontColor;
    element.text = textLines[theNextAfterNext].text;
  }
  return element;
}

export function getTextIndex(index, textLines) {
  const nextIndex = index + 1;
  if (nextIndex >= textLines.length)
    return 0;
  return nextIndex;
}

export function getGetNextSlide(textLines, transitionRef, elementToRepresentNextText, coverRef, getTransitionsIndex) {
  return state => {
    const nextIndex = getTextIndex(state.index, textLines);
    return {
      displayText: textLines[state.index].text,
      backgroundColor: textLines[nextIndex].backgroundColor,
      index: nextIndex,
      elements: [
        modifyTextLine(state.elements[0], nextIndex, transitionRef, textLines, elementToRepresentNextText, coverRef,
          getTransitionsIndex),
        modifyTextLine(state.elements[1], nextIndex, transitionRef, textLines, elementToRepresentNextText, coverRef,
          getTransitionsIndex),
        modifyTextLine(state.elements[2], nextIndex, transitionRef, textLines, elementToRepresentNextText, coverRef,
          getTransitionsIndex),
      ],
    };
  };
}

export function loadAnimation(
  setState,
  rotationDelay,
  textLines,
  transitionRef,
  elementToRepresentNextText,
  coverRef,
  getTransitionsIndex,
  indexForSecondText,
  indexForThirdText,
  timerClearanceRef,
) {
  setState(getVerticalAlignmentBaseOnRenderedElements(
    rotationDelay,
    coverRef,
    textLines,
    indexForSecondText,
    indexForThirdText));
  if (rotationDelay !== 0) {
    if (timerClearanceRef.current.pause)
      timerClearanceRef.current.pause();
    timerClearanceRef.current.play = () => {
      const interval = setInterval(() => {
        setState(getGetNextSlide(textLines, transitionRef, elementToRepresentNextText, coverRef, getTransitionsIndex));
      }, rotationDelay * 1000);
      timerClearanceRef.current.pause = () => {
        clearInterval(interval);
        timerClearanceRef.current.isPlaying = false;
      };
      timerClearanceRef.current.isPlaying = true;
    };
    timerClearanceRef.current.play();
  }
}

const VerticalScroll = ({ model, textLines, rotationDelay, transitionData }) => {
  const coverRef = useRef(null),
    topElementRef = useRef(null),
    middleElementRef = useRef(null),
    bottomElementRef = useRef(null);
  const transitionRef = useMemo(() =>
    [transitionData.transitionSpeedFirstElement, transitionData.transitionSpeed,
    transitionData.transitionSpeedSecondElement],
    [transitionData]);
  const [state, setState] = useState(model);
  const timer = useRef({
    isPlaying: true,
    pausedByButton: false,
    play: () => { },
    pause: () => { },
  });

  useEffect(() => loadAnimation(
    setState,
    rotationDelay,
    textLines,
    transitionRef,
    transitionData.getElementToRepresentNextText(topElementRef, bottomElementRef),
    coverRef,
    transitionData.getTransitionsIndex,
    transitionData.indexForSecondText,
    transitionData.indexForThirdText,
    timer)
    , [rotationDelay, textLines, transitionData, transitionRef]);

  return (
    <>
      <Accessibility textLines={textLines}
        onClick={() => {
          const currentTimer = timer.current;
          if (currentTimer.isPlaying) {
            currentTimer.pause();
            currentTimer.pausedByButton = true;
          }
          else {
            currentTimer.play();
            currentTimer.pausedByButton = false;
          }
        }}
      />
      <div className={styles.bottomTop} aria-hidden
        onMouseEnter={() => {
          const currentTimer = timer.current;
          if (currentTimer.pausedByButton)
            return;
          if (currentTimer.isPlaying)
            currentTimer.pause();
        }}
        onMouseLeave={() => {
          const currentTimer = timer.current;
          if (currentTimer.pausedByButton)
            return;
          if (!currentTimer.isPlaying)
            currentTimer.play();
        }}
      >
        {renderHTML(state.displayText)}
        <div ref={coverRef} className={styles.cover} style={{ backgroundColor: `${state.backgroundColor}` }} />
        <div ref={topElementRef}
          className={styles.line}
          style={{
            top: `${state.elements[0].top}px`,
            color: `${state.elements[0].fontColor}`,
            transition: `${state.elements[0].transition}s`,
            visibility: `${state.elements[0].visibility}`,
          }}
        >
          <span className={styles.fullWidthSpan}>{renderHTML(state.elements[0].text)}</span>
        </div>
        <div ref={middleElementRef}
          className={styles.line}
          style={{
            top: `${state.elements[1].top}px`,
            color: `${state.elements[1].fontColor}`,
            transition: `${state.elements[1].transition}s`,
          }}
        >
          <span className={styles.fullWidthSpan}>{renderHTML(state.elements[1].text)}</span>
        </div>
        <div ref={bottomElementRef}
          className={styles.line}
          style={{
            top: `${state.elements[2].top}px`,
            color: `${state.elements[2].fontColor}`,
            transition: `${state.elements[2].transition}s`,
            visibility: `${state.elements[2].visibility}`,
          }}
        >
          <span className={styles.fullWidthSpan}>{renderHTML(state.elements[2].text)}</span>
        </div>
      </div>
    </>
  );
};

VerticalScroll.propTypes = {
  model: PropTypes.shape({
    textLines: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      fontColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })),
    rotationDelay: PropTypes.number,
    transitionAs: PropTypes.number,
  }),
  textLines: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string,
    fontColor: PropTypes.string,
    backgroundColor: PropTypes.string,
  })),
  rotationDelay: PropTypes.number,
  transitionData: PropTypes.shape({
    transitionSpeedFirstElement: PropTypes.number,
    transitionSpeedSecondElement: PropTypes.number,
    getElementToRepresentNextText: PropTypes.func,
    getTransitionsIndex: PropTypes.func,
  }),
};

export default VerticalScroll;