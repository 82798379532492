import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ActionButton from './ActionButton';
import ScreenReaderTexts from './ScreenReaderTexts';

const Accessibility = ({ textLines, onClick }) => {
  const [isFocused, setFocus] = useState(false);
  const [hasPaused, setPause] = useState(false);

  return (
    <>
      <ActionButton
        isFocused={isFocused}
        hasPaused={hasPaused}
        textLines={textLines}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        onClick={() => {
          onClick();
          setPause(!hasPaused);
        }}
      />
      <ScreenReaderTexts displayAsNone={!hasPaused} textLines={textLines} />
    </>
  );
};

Accessibility.propTypes = {
  textLines: PropTypes.array,
  onClick: PropTypes.func,
};

export default React.memo(Accessibility);