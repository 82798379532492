exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.39.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".UspBarContentBlock__VerticalScroll_bottomTop{background-color:#000;overflow:hidden;position:relative;width:100%}.UspBarContentBlock__VerticalScroll_cover{background-color:pink;top:0;transition:2s}.UspBarContentBlock__VerticalScroll_cover,.UspBarContentBlock__VerticalScroll_line{height:100%;position:absolute;width:100%}.UspBarContentBlock__VerticalScroll_line{align-items:center;display:flex}.UspBarContentBlock__VerticalScroll_fullWidthSpan{width:100%}", ""]);

// exports
exports.locals = {
	"bottomTop": "UspBarContentBlock__VerticalScroll_bottomTop",
	"cover": "UspBarContentBlock__VerticalScroll_cover",
	"line": "UspBarContentBlock__VerticalScroll_line",
	"fullWidthSpan": "UspBarContentBlock__VerticalScroll_fullWidthSpan"
};