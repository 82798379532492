/*
 * WCAG related implementation.
 * ============================
 * Idel implementaion of Play/Pause button would be to isolation it from all the effects, using ashared state between the
 * button and the effects.
 *
 * But considering the followings
 * 1. As the MVP product, the add-on has only Vertical Scroller effect.
 * 2. Vertical Scroller has no side effects.
 * 2. Need to reduce/keep it minimum the QA and DEV for WCAG MVP release.
 * The Play/pause button is implemented only to Vertical Scroller.
 *
 * We need to consider the other implementaion when we plan introduce other effects in future.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Bar.module.scss';
import { getAnimation } from './effects/library';

export function getOrDefault(value, defaultValue) {
  if (!value)
    return defaultValue;
  return value;
}

export function getDefaultValuesIfNeeded(model) {
  return model.map(m => {
    return {
      ...m,
      fontColor: getOrDefault(m.fontColor, '#FFFFFF'),
      backgroundColor: getOrDefault(m.backgroundColor, '#000000'),
    };
  });
}

const Bar = ({ model }) => {
  model.textLines = getDefaultValuesIfNeeded(model.textLines.filter(m => m.visible !== false).sort(function (a, b) {
    return a.displayIndex - b.displayIndex;
  }));
  const [Animation, data, transitionData] = getAnimation(model);
  return (
    <div className={classNames('usp-bar-content-block', styles.bar)}>
      <Animation
        model={data}
        textLines={model.textLines}
        rotationDelay={model.rotationDelay}
        transitionData={transitionData}
      />
    </div>
  );
};

Bar.propTypes = {
  model: PropTypes.shape({
    textLines: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string.isRequired,
      fontColor: PropTypes.string,
      backgroundColor: PropTypes.string,
    })),
    rotationDelay: PropTypes.number.isRequired,
    transitionAs: PropTypes.number.isRequired,
  }),
};

export default Bar;