exports = module.exports = require("C:\\addon-agent\\Build Files\\Other\\SDK\\1.0.39.34\\Sources\\SDK\\Sana.Commerce.WebApp\\ClientApp\\node_modules\\css-loader\\lib\\css-base.js")(false);
// imports


// module
exports.push([module.id, ".UspBarContentBlock__ActionButton_hidden{clip:rect(0,0,0,0);position:absolute}.UspBarContentBlock__ActionButton_action svg rect.UspBarContentBlock__ActionButton_background{stroke:#000;fill:#000;stroke-width:1px;opacity:.6}.UspBarContentBlock__ActionButton_action svg rect.UspBarContentBlock__ActionButton_border{fill:transparent;stroke:#fff;stroke-width:2px}.UspBarContentBlock__ActionButton_action.UspBarContentBlock__ActionButton_rotation.UspBarContentBlock__ActionButton_pause svg polygon.UspBarContentBlock__ActionButton_pause,button.UspBarContentBlock__ActionButton_rotation.UspBarContentBlock__ActionButton_play svg polygon.UspBarContentBlock__ActionButton_play{fill:#fff;stroke:#fff}.UspBarContentBlock__ActionButton_action.UspBarContentBlock__ActionButton_rotation svg polygon.UspBarContentBlock__ActionButton_play{stroke-width:1;fill:transparent;stroke:transparent}.UspBarContentBlock__ActionButton_action svg polygon{stroke:#fff;fill:#fff;stroke-width:2;opacity:1}.UspBarContentBlock__ActionButton_action.UspBarContentBlock__ActionButton_rotation svg polygon.UspBarContentBlock__ActionButton_pause{stroke-width:4;fill:transparent;stroke:transparent}.UspBarContentBlock__ActionButton_action{background-color:transparent;border:none;height:22px;left:2px;outline:none;padding:0;position:absolute;top:2px;z-index:10}", ""]);

// exports
exports.locals = {
	"hidden": "UspBarContentBlock__ActionButton_hidden",
	"action": "UspBarContentBlock__ActionButton_action",
	"background": "UspBarContentBlock__ActionButton_background",
	"border": "UspBarContentBlock__ActionButton_border",
	"rotation": "UspBarContentBlock__ActionButton_rotation",
	"pause": "UspBarContentBlock__ActionButton_pause",
	"play": "UspBarContentBlock__ActionButton_play"
};