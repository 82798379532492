import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ScreenReaderTexts.module.scss';
import { renderHTML } from 'sana/utils';

const ScreenReaderTexts = ({ displayAsNone, textLines }) => {
  return (
    <div role="list" className={classNames(displayAsNone ? styles.remove : styles.hidden)}>{
      textLines.map((textLine, index) =>
      (
        <div key={index} role="listitem">
          {renderHTML(textLine.text)}
        </div>
      ))}
    </div>
  );
};

ScreenReaderTexts.propTypes = {
  displayAsNone: PropTypes.bool,
  textLines: PropTypes.array,
};

export default React.memo(ScreenReaderTexts);